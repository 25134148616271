import { useEffect, useState } from "react";
import "./Home.css";
import "animate.css";
import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/offcanvas";

const colors = [
  { background: "#181C2D", color: "#ffffff" },
  { background: "#BB6464", color: "#111111" },
  { background: "#94B49F", color: "#111111" },
  { background: "#372948", color: "#ffffff" },
  { background: "#ECDBBA", color: "#111111" },
];

function Home() {
  var i = 0;
  const [background, setBackground] = useState("#181C2D");
  const [colorText, setColorText] = useState("#ffffff");

  return (
    <>
      <div
        className="offcanvas offcanvas-top"
        tabindex="-1"
        id="offcanvasTop"
        aria-labelledby="offcanvasTopLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasTopLabel"></h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#sobre">
                Sobre
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#projetos">
                Projetos
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href="/pdf/CurriculoRichard.pdf"
              >
                Resumo
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section
        className="section-header"
        style={{ background: background, color: colorText }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="d-flex justify-content-between mb-5 animate__animated animate__delay-2s animate__fadeInDown">
                <ul className="w-100 d-flex align-items-center">
                  <li className="d-md-none">
                    <div
                      className="button-menu navbar-toggler fs-3"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasTop"
                      aria-controls="offcanvasTop"
                      aria-label="Toggle navigation"
                    >
                      |||
                    </div>
                  </li>
                  <li className="d-none d-md-block">
                    <a
                      className="ps-0"
                      style={{ color: colorText }}
                      aria-current="page"
                      href="#sobre"
                    >
                      Sobre
                    </a>
                  </li>
                  <li className="d-none d-md-block">
                    <a
                      style={{ color: colorText }}
                      aria-current="page"
                      href="#projetos"
                    >
                      Projetos
                    </a>
                  </li>
                  <li className="flex-grow-1"></li>
                  <li className="d-none d-md-block">
                    <a
                      style={{ color: colorText }}
                      aria-current="page"
                      target="_blank"
                      href="/pdf/CurriculoRichard.pdf"
                    >
                      Resumo
                    </a>
                  </li>
                  <li className="float-end">
                    <div>
                      <a
                        style={{ color: colorText }}
                        aria-current="page"
                        target="_blank"
                        href="https://wa.me/5548988143991?text=Ol%C3%A1,%20acabei%20de%20ver%20o%20seu%20portf%C3%B3lio%20e%20gostaria%20de%20trocar%20uma%20ideia%20com%20voc%C3%AA!"
                        className="pe-0"
                      >
                        <div className="btn-fale-comigo">Fale comigo!</div>
                      </a>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <img
                className="foto-perfil-quadrada animate__animated animate__fadeInLeft"
                src="/media/misc/foto-quadrada.png"
                alt=""
              />
            </div>
            <div className="col-md-6 pt-4 pt-md-0 ps-md-5 animate__animated animate__fadeInRight">
              <div className="fs-3">Olá! Me chamo</div>
              <div className="fs-2 fw-bolder">Richard Cardoso</div>

              <hr
                className="header"
                style={{ width: "40px", borderTop: `1px solid ${colorText}` }}
              />

              <div>Projetando interfaces de usuário e</div>
              <div>desenvolvendo sistemas por</div>
              <div>mais de 8 anos.</div>
              <div className="my-5 text-start">
                <a
                  className="d-flex align-items-center"
                  style={{ color: colorText, textDecoration: "none" }}
                  href="https://www.linkedin.com/in/richardpcardoso/"
                >
                  <span className="icon-redes-sociais icon-linkedin"></span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="my-5"></div>
      <section id="sobre">
        <div className="container animate__animated animate__delay-2s animate__fadeIn">
          <div className="row pt-5 justify-content-md-between">
            <div className="col-md-6 pt-5">
              <div className="fs-4 fw-bolder text-black">Minha jornada</div>
              <div className="fs-6 fw-bolder text-greey">
                como desenvolvedor e UX 
              </div>

              <div className="mt-4">
                Em 2016 cursei técnico em informática com programação no CEDUP
                em Tubarão - SC, onde encontrei uma vaga de estágio na empresa
                Ticket Mais Soluções em TI.
              </div>
              {/* <div className="mt-4">
                Lá eu tive um enorme crescimento pessoal e profissional,
                consegui evoluir muito ao longo desses quase 7 anos que
                participei da empresa. Me tornei líder do setor e gerenciei um
                time de mais 5 pessoas.
              </div> */}
              <div className="mt-4">
                Durante os quase sete anos em que estive na empresa, eu tive um
                enorme crescimento pessoal e profissional onde alcancei a
                posição de líder do setor e assumi a gestão de uma equipe com
                mais cinco pessoas.
              </div>
              <div className="mt-4">
                Após essa experiência, ingressei na equipe da Betha Sistemas na área de
                UX, onde ainda permaneço, sendo responsável pelo design e
                experiência do usuário de sete sistemas.
              </div>
            </div>
            <div className="col-md-4 mt-5 mt-md-0">
              <video className="elementor-video" width={"100%"} height={"450px"}
                     src="/media/misc/treinamento.mp4" loop="true"
                     autoPlay="true" muted="muted" playsInline="" controlsList="nodownload"></video>
              {/*<video*/}
              {/*    style={{borderRadius: "10px"}}*/}
              {/*    width={"100%"}*/}
              {/*    height={"450px"}*/}
              {/*    loop="true"*/}
              {/*    autoPlay="true"*/}
              {/*    muted*/}
              {/*>*/}
              {/*  <source src="/media/misc/treinamento.mp4" type="video/mp4"/>*/}
              {/*  Your browser does not support the video tag.*/}
              {/*</video>*/}
            </div>
          </div>
          <hr className="m-5"/>
          <div className="row pb-3" id="projetos">
            <div className="col-12 text-center">
              <div className="fs-4 fw-bolder">Principais projetos</div>
              <div className="fs-6 fw-bold text-greey">
                que tive a oportunidade de participar:
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-5 order-1 order-md-first">
              <div className="fs-5 fw-bolder text-black">App do Bar Fácil</div>
              <div className="mt-3">
                O app Bar Fácil é destinado ao consumidor final e ao público do
                evento. Com a plataforma, os mesmos conseguem realizar o consumo
                indo diretamente no balcão, sem necessidade de passar no caixa
                para adquirir bilhetes ou colocar créditos via cartão cashless,
                por exemplo.
              </div>
              <div className="mt-3">
                Nesse projeto eu tive a oportunidade de gerenciar desde o início
                contando com toda a prototipagem, desenvolvimento e rodando ele
                em produção!
              </div>
              <div className="mt-3">
                As principais stacks desses projetos são PHP (Laravel) e
                ReactJS.
              </div>
            </div>
            <div className="col-md-7 order-0 order-md-last">
              <img className="w-100" src="/media/projetos/4.png" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section-projects bg-greey-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img className="w-100" src="/media/projetos/1.png" alt="" />
            </div>
            <div className="col-md-6 ps-3 mt-3 mt-md-0">
              <div className="fs-5 fw-bolder text-black">AGPIX</div>
              <div className="mt-3">
                O agpix é uma carteira digital exclusiva da Bar Fácil e Ticket
                Mais, onde os produtores de eventos conseguem receber os valores
                transacionados em ambos aplicativos direto em sua carteira, onde
                conseguem efetuar pagamentos de boleto, transferências e pix.
              </div>
              <div className="mt-3">
                A principal Stack utilizada nesse projeto é o PHP (Laravel).
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 mt-3 mt-md-0 order-1 order-md-first">
              <div className="fs-5 fw-bolder text-black">AGPAG</div>
              <div className="mt-3">
                O agpag é um gateway de pagamentos onde os clientes conseguem
                plugar suas adquirentes como Mercado Pago, Rede, Vindi e entre
                outros.
              </div>
              <div className="mt-3">
                A principal Stack utilizada nesse projeto é o PHP (Laravel).
              </div>
            </div>
            <div className="col-md-6 px-md-4">
              <img className="w-100" src="/media/projetos/2.png" alt="" />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img className="w-100" src="/media/projetos/3.png" alt="" />
            </div>
            <div className="col-md-6 ps-3 mt-3 mt-md-0">
              <div className="fs-5 fw-bolder text-black">Ticket Mais</div>
              <div className="mt-3">
                Em 2018 eu tive a oportunidade de desenhar todo o layout desse
                site, foi a primeira prototipagem de ponta a ponta.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="fs-5 fw-bolder text-black">Vivência</div>
              <div className="mt-3">
                Por alguns anos tive contato diretamente com alguns dos
                principais clientes de diferentes tipos de segmento buscando
                melhores oportunidades de negócio, efetuando treinamentos,
                analisando as tendências e melhorias, criando novas
                funcionalidades e produtos.
              </div>
            </div>
            <div className="col-md-6 px-4">
              <div
                id="carouselExampleDark"
                className="carousel carousel-dark slide carousel-fade"
                data-bs-ride="carousel"
              >
                <div className="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="0"
                    className="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselExampleDark"
                    data-bs-slide-to="3"
                    aria-label="Slide 4"
                  ></button>
                </div>
                <div className="carousel-inner">
                  <div className="carousel-item active" data-bs-interval="2000">
                    <img
                      src="/media/vivencia/3.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item" data-bs-interval="2000">
                    <img
                      src="/media/vivencia/2.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item" data-bs-interval="2000">
                    <img
                      src="/media/vivencia/1.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item" data-bs-interval="2000">
                    <img
                      src="/media/vivencia/4.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="my-5"></div>
      <section style={{ background: background, color: colorText }}>
        <div className="container">
          <div className="d-flex flex-column flex-md-row justify-content-between text-white py-5">
            <div className="d-flex align-items-center py-2">
              <span className="icon-redes-sociais icon-email"></span>{" "}
              richardpcardoso@gmail.com
            </div>
            <div className="text-white ">
              <a
                className="d-flex align-items-center py-2"
                style={{ color: colorText, textDecoration: "none" }}
                href="wa.me/5548988143391"
              >
                <span className="icon-redes-sociais icon-whats"></span>
                +55 (48) 98814-3991
              </a>
            </div>
            <div className="">
              <a
                className="d-flex align-items-center py-2"
                style={{ color: colorText, textDecoration: "none" }}
                href="https://www.linkedin.com/in/richardpcardoso/"
              >
                <span className="icon-redes-sociais icon-linkedin"></span>
                /richardpcardoso
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
